<template>
  <section>
    <div class="row">
      <div class="col-md-7">
        <h2 class="titulo-links-escolha">Rematricula</h2>
      </div>
    </div>
    <div class="pm-card" v-if="etapa1">
      <div class="col-md-12" v-if="select.length>0">
        <button type="button" class="btn btn-primary" style="float: right;margin-bottom: 10px;" @click="avancarTwo">
          Avançar 
        </button>
      </div>

      <div class="form-group">
        <label for="searchInput">Pesquisar por Nome da mãe ou CPF:</label>
        <input type="text" class="form-control" placeholder="Digite o nome da mãe ou CPF" @keypress="pesquisar" style="height: 50px;">
      </div> 

      <div class="col-md-12 select-box" v-for="aluno in alunos" :key="aluno">
        <div class="row" >
          <div class="col-md-1" style="width: 3%;justify-content: center;align-items: center;display: flex;">
            <div class="form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" v-model="select" :value="aluno">
              </label>
            </div>
          </div>
          <div class="col-md-1" style="width: 2%;justify-content: center; align-items: center; display: flex;">
            <div v-if="aluno.foto">
              <img :src="baseUrl + '/storage/' + aluno.foto" class="img-perfil-dtb-pq">
            </div>
            <div v-else>
              <pm-Avatar
                icon="pi pi-user"
                class="mr-2"
                style="background-color: #e1e1e1; color: #ffffff"
                shape="circle"
              />
            </div>
          </div>
          <div class="col-md-10">
            <label class="col-md-12" style="margin-bottom: 0px !important;margin-top: 0px !important;">
              <div class="badge badge-danger" style="color:#ffffff;background-color: #ed2e2e;">
                <b>Não Rematriculado!</b>
              </div>
            </label>
            <label class="col-md-12" style="margin-bottom: 0px !important;margin-top: 0px !important;"><b>Aluno:</b> {{ aluno.nome }} {{ aluno.sobrenome }}</label>
            <label class="col-md-12" style="margin-bottom: 0px !important;margin-top: 0px !important;"><b>Mãe:</b> {{ aluno.nome_mae }}</label>
            <label class="col-md-12" style="margin-bottom: 0px !important;margin-top: 0px !important;"><b>CPF:</b> {{ aluno.cpf }}</label>
          </div> 
        </div>  
      </div>
    </div>

    <div class="pm-card" v-if="etapa2">
      <div class="col-md-12" style="margin-top:10px;">
        <div class="row">
          <div class="col-md-6">
            <button type="button" class="btn btn-primary" style="margin-bottom: 10px;" @click="retornarOne()">
              Voltar
            </button>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <br>
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item" v-for="(tab, index) in select" :key="index">
            <a
              :class="['nav-link', { active: tab.isActive }]"
              :id="tab.id + '-tab'"
              data-toggle="tab"
              :href="'#' + tab.id"
              role="tab"
              :aria-controls="tab.id"
              @click="activateTab(index)"
            >
              {{ tab.nome }} {{ tab.sobrenome }}
            </a>
          </li>
        </ul>

        <div class="tab-content">
          <div
            class="tab-pane fade"
            :class="[ { 'show active': aluno.isActive == true }]"
            v-for="(aluno, index) in select"
            :key="index"
            :id="aluno.id"
            role="tabpanel"
            :aria-labelledby="aluno.id + '-tab'"
          >
            <!-- Conteúdo da aba -->
            <!--<p>{{ tab}}</p>-->
            <div  class="row" style="padding:20px;">
              <!--{{ aluno }}-->
              <div  class="col-md-2">
                <div class="form-group" style="width: 80%; margin-top: 15px;">
                  <div v-if="aluno.foto">
                    <img :src="baseUrl + '/storage/' + aluno.foto" style="width:100%;height:180px;border: 5px solid #efefef;">
                  </div>
                  <div v-else >
                    <img src="https://cdn-icons-png.flaticon.com/512/1695/1695213.png" style="width:100%;height:180px;border: 5px solid #efefef;">
                  </div>
                </div>
              </div>
              <div  class="col-md-12"></div>
              <div  class="col-md-2">
                <div class="form-group">
                  <label>CPF:</label>
                  <input type="text" class="form-control" placeholder="Digite o CPF" :value="aluno.cpf">
                </div>
              </div>
              <div  class="col-md-3">
                <div class="form-group">
                  <label>Primeiro nome:</label>
                  <input type="text" class="form-control" placeholder="Digite o Primeiro nome" :value="aluno.nome">
                </div>
              </div>
              <div  class="col-md-3">
                <div class="form-group">
                  <label>Sobrenome do aluno:</label>
                  <input type="text" class="form-control" placeholder="Digite o Primeiro nome" :value="aluno.sobrenome">
                </div>
              </div>
              <div  class="col-md-1">
                <div class="form-group">
                  <label>Nascimento:</label>
                  <input type="text" class="form-control" placeholder="Digite o Primeiro nome" :value="aluno.nascimento">
                </div>
              </div>
              <div  class="col-md-3">
                <div class="form-group">
                  <label>Responsável pelo Aluno:</label>
                  <input type="text" class="form-control" placeholder="Digite o Primeiro nome" :value="aluno.nome_responsavel">
                </div>
              </div>

              <div  class="col-md-3">
                <div class="form-group">
                  <label>Nome da mãe:</label>
                  <input type="text" class="form-control" placeholder="Digite o Primeiro nome" :value="aluno.nome_mae">
                </div>
              </div>
              <div  class="col-md-3">
                <div class="form-group">
                  <label>Nome do pai:</label>
                  <input type="text" class="form-control" placeholder="Digite o Primeiro nome" :value="aluno.nome_pai">
                </div>
              </div>
              <!-- <div class="row col-md-3">
                <div class="field-radiobutton" style="margin-top: 35px">
                  <label>Sexo:</label>&nbsp;&nbsp;
                  <pm-RadioButton name="masc" value="M"  />
                  <label>Masculino</label>
                  <pm-RadioButton name="femi" value="F" style="margin-left: 7px"/>
                  <label>Feminino</label>
                  <pm-RadioButton name="outros" value="O" style="margin-left: 7px"/>
                  <label>Outros</label>
                </div>
              </div>

              <div class="row field col-12 md:col-3">
                <div class="field-radiobutton" style="margin-top: 35px">
                  <label>Autorização para uso de imagem?</label> &nbsp;&nbsp;
                  <pm-RadioButton name="sim" value="1" />
                  <label>Sim</label>
                  <pm-RadioButton name="nao" value="0" style="margin-left: 7px"/>
                  <label>Não</label>
                </div>
              </div>

              <div  class="col-md-12"><br> <hr> <br></div>

              
              <div  class="col-md-3">
                <div class="form-group">
                  <label>Nacionalidade:</label>
                  <input type="text" class="form-control" placeholder="Digite o Primeiro nome" :value="aluno.nome_pai">
                </div>
              </div>

              <div  class="col-md-3">
                <div class="form-group">
                  <label>Naturalidade:</label>
                  <input type="text" class="form-control" placeholder="Digite o Primeiro nome" :value="aluno.nome_pai">
                </div>
              </div>

              <div class="field col-12 md:col-2">
                <label for="sangue">Cor/raça:</label>
                <pm-Dropdown
                  :options="corRacaALL"
                  placeholder="Selecione a cor/raça"
                />
              </div>

              <div  class="col-md-3">
                <div class="form-group">
                  <label>Religião:</label>
                  <input type="text" class="form-control" placeholder="Digite o Primeiro nome" :value="aluno.nome_pai">
                </div>
              </div>

              <div class="row col-md-3">
                <div class="field-radiobutton" style="margin-top: 24px">
                  <label>Frequentará aulas de ensino religioso?</label>&nbsp;
                  <pm-RadioButton name="sim" value="1"/>
                  <label for="solteiro">Sim</label>
                  <pm-RadioButton name="nao" value="0" style="margin-left: 7px"/>
                  <label for="casado">Não</label>
                </div>
              </div>

              <div  class="col-md-3">
                <div class="form-group">
                  <label>Documento de Identidade:</label>
                  <input type="text" class="form-control" placeholder="Digite o Primeiro nome" :value="aluno.nome_pai">
                </div>
              </div>

              <div  class="col-md-3">
                <div class="form-group">
                  <label>Orgão emissor:</label>
                  <input type="text" class="form-control" placeholder="Digite o Primeiro nome" :value="aluno.nome_pai">
                </div>
              </div>

              <div  class="col-md-3">
                <div class="form-group">
                  <label>UF:</label>
                  <input type="text" class="form-control" placeholder="Digite o Primeiro nome" :value="aluno.nome_pai">
                </div>
              </div>

              <div  class="col-md-3">
                <div class="form-group">
                  <label>Data de emissão:</label>
                  <input type="text" class="form-control" placeholder="Digite o Primeiro nome" :value="aluno.nome_pai">
                </div>
              </div>

              <div class="row col-md-5">
                <div class="field-radiobutton" style="margin-top: 24px">
                  <label>Estado civil:</label>&nbsp;&nbsp;
                  <pm-RadioButton name="Solteiro" value="Solteiro"
                  />
                  <label for="solteiro">Solteiro</label>
                  <pm-RadioButton name="Casado" value="Casado" style="margin-left: 7px"
                  />
                  <label for="casado">Casado</label>
                  <pm-RadioButton name="Viuvo" value="Viuvo" style="margin-left: 7px"
                  />
                  <label for="viuvo">Viúvo</label>
                  <pm-RadioButton name="Divorciado" value="Divorciado" style="margin-left: 7px"
                  />
                  <label for="divorciado">Divorciado</label>
                  <pm-RadioButton name="Outros" value="Outros" style="margin-left: 7px"
                  />
                  <label for="outros">Outros</label>
                </div>
              </div>

              <div  class="col-md-3">
                <div class="form-group">
                  <label>Certidão de nascimento:</label>
                  <input type="text" class="form-control" placeholder="Digite o Primeiro nome" :value="aluno.nome_pai">
                </div>
              </div>

              <div  class="col-md-3">
                <div class="form-group">
                  <label>Livro:</label>
                  <input type="text" class="form-control" placeholder="Digite o Primeiro nome" :value="aluno.nome_pai">
                </div>
              </div>

              <div  class="col-md-3">
                <div class="form-group">
                  <label>Folha:</label>
                  <input type="text" class="form-control" placeholder="Digite o Primeiro nome" :value="aluno.nome_pai">
                </div>
              </div>

              <div  class="col-md-3">
                <div class="form-group">
                  <label>Termo:</label>
                  <input type="text" class="form-control" placeholder="Digite o Primeiro nome" :value="aluno.nome_pai">
                </div>
              </div>

              <div  class="col-md-3">
                <div class="form-group">
                  <label>NIS:</label>
                  <input type="text" class="form-control" placeholder="Digite o Primeiro nome" :value="aluno.nome_pai">
                </div>
              </div>

              <div  class="col-md-3">
                <div class="form-group">
                  <label>SUS:</label>
                  <input type="text" class="form-control" placeholder="Digite o Primeiro nome" :value="aluno.nome_pai">
                </div>
              </div>

              <div class="col-md-2">
                <label for="sangue">Tipo sanguíneo:</label>
                <pm-Dropdown
                  :options="tipoSanguineoALL"
                  placeholder="Selecione o tipo sanguineo"
                />
              </div>

              <div class="col-md-12"></div>
              <div class="col-md-3" style="padding-top: 16px">
                <label
                >Portador(a) de Necessidade especial?
                  <pm-InputSwitch
                    class="small-switch"
                    style="vertical-align: middle !important"
                  /></label>

                <div >
                  <label>Qual à Necessidade especial?</label>
                  <pm-Textarea  />
                </div>

                <div  style="margin-top: 15px">
                  <label
                  >O aluno necessita de Mediação Escolar?
                    <pm-InputSwitch
                      class="small-switch"
                      style="vertical-align: middle !important"
                    />
                  </label>
                </div>

                <div style="margin-top: 15px">
                  <label
                  >O aluno necessita de atendimento especial(NEE/PCD)?
                    <pm-InputSwitch
                      class="small-switch"
                      style="vertical-align: middle !important"
                    />
                  </label>
                </div>
              </div>

              <div class="col-md-3" style="padding-top: 16px">
                <label
                >Alérgico(a)?
                  <pm-InputSwitch
                    class="small-switch"
                    style="vertical-align: middle !important"
                  /></label>
                <div >
                  <label for="lastname">Tipo de alergia?</label>
                  <pm-Textarea id="lastname"  />
                </div>
              </div>

              <div  class="col-md-12"><br><hr><br></div>

              <div class="field col-12 md:col-12">
                <h5>Conectividade:</h5>
              </div>
              <div class="row field col-12 md:col-3">
                <h6>Possui dispositivo com acesso à internet?</h6>
                <div class="field-radiobutton">
                  <pm-RadioButton name="sim" value="1" />
                  <label for="solteiro">Sim</label>
                  <pm-RadioButton name="nao" value="0" style="margin-left: 7px"/>
                  <label for="casado">Não</label>
                </div>
              </div>

              <div class="field col-12 md:col-3" >
                <label for="sangue">Tipo de dispositivo:</label>
                <pm-MultiSelect
                  :options="dispositivoALL"
                  placeholder="Selecione um ou mais"
                />
                <small class="p-error">Selecione pelo menos um dispositivo!</small>
              </div>


              <div  class="col-md-12"><br><hr><br></div>

              <div  class="col-md-3">
                <div class="form-group">
                  <label>CEP:</label>
                  <input type="text" class="form-control" placeholder="Digite o Primeiro nome" :value="aluno.nome_pai">
                </div>
              </div>

              <div  class="col-md-3">
                <div class="form-group">
                  <label>Logradouro:</label>
                  <input type="text" class="form-control" placeholder="Digite o Primeiro nome" :value="aluno.nome_pai">
                </div>
              </div>

              <div  class="col-md-3">
                <div class="form-group">
                  <label>Número:</label>
                  <input type="text" class="form-control" placeholder="Digite o Primeiro nome" :value="aluno.nome_pai">
                </div>
              </div>

              <div  class="col-md-3">
                <div class="form-group">
                  <label>Complemento:</label>
                  <input type="text" class="form-control" placeholder="Digite o Primeiro nome" :value="aluno.nome_pai">
                </div>
              </div>

              <div  class="col-md-3">
                <div class="form-group">
                  <label>Bairro/Distrito:</label>
                  <input type="text" class="form-control" placeholder="Digite o Primeiro nome" :value="aluno.nome_pai">
                </div>
              </div>

              <div  class="col-md-3">
                <div class="form-group">
                  <label>Cidade:</label>
                  <input type="text" class="form-control" placeholder="Digite o Primeiro nome" :value="aluno.nome_pai">
                </div>
              </div>

              <div  class="col-md-3">
                <div class="form-group">
                  <label>Estado:</label>
                  <input type="text" class="form-control" placeholder="Digite o Primeiro nome" :value="aluno.nome_pai">
                </div>
              </div>

              <div class="row field col-12 md:col-12">
                <div class="field-radiobutton" style="margin-top:24px;" >
                  <label>Utiliza transporte?</label>&nbsp;
                  <input type="radio" name="sim" value="1"/>
                  <label for="sim">Sim</label>
                  <input type="radio" name="nao" value="0" style="margin-left:7px;" />
                  <label for="nao">Não</label>
                </div>
                <div>
                  <label >Qual transporte?</label>
               
                </div>
              </div> -->


            </div>  
          </div>
        </div>

      </div>
    </div>
  </section>
</template>
  
<script>
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
components:{
},
data() {
  return {
    baseUrl: axios.defaults.baseURL.replace("/api/v1", ""),
    alunos:[],
    select:[],
    etapa1:true,
    etapa2:false,
    tipoSanguineoALL: [
      "A+",
      "A-",
      "B+",
      "B-",
      "AB+",
      "AB-",
      "O+",
      "O-",
      "NÃO DECLARADA",
    ],
    corRacaALL: ["BRANCA", "PARDA", "PRETA", "INDÍGENA", "NÃO DECLARADA"],
    dispositivoALL: ["CELULAR", "COMPUTADOR", "TABLET", "OUTROS"],
  };
},
//async beforeMount() {},
methods: {
  async  pesquisar(event) {
    if (event.key === 'Enter') {
      this.alunos = [];
      const valorPesquisa = event.target.value;
      const res = await axios.post('matriculas/aluno',{ 'pesquisa': valorPesquisa});
      this.alunos = res.data;
    }
  },
  retornarOne(){
    this.etapa1 = true
    this.etapa2 = false
  },
  avancarTwo(){
    this.etapa1 = false
    this.etapa2 = true

    for (let index = 0; index < this.select.length; index++) {
      if(index == 0){
        this.select[index]['isActive'] = true;
      }else{
        this.select[index]['isActive'] = false;
      }
    }
  },
  activateTab(index) {
    this.select.forEach((tab, i) => {
      tab.isActive = i === index;
    });
  }
}
});
</script>

<style>
.select-box{
  border: 1px #acacac solid;padding: 15px;margin-top: 0px;border-radius:0px;margin-left: 5px;
  -webkit-box-shadow: 25px 3px 21px -14px rgba(201,201,201,1);
  -moz-box-shadow: 25px 3px 21px -14px rgba(201,201,201,1);
  box-shadow: 25px 3px 21px -14px rgba(201,201,201,1);
}
.select-box:hover{
  background-color: #e9e9e9;
}
</style>